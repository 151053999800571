
import { Component, Vue } from 'vue-property-decorator';
import PageTabs from '@/components/PageTabs.vue';
import ProposalTableQueue from '@/components/proposal-tables/ProposalTableQueue.vue';
import ProposalTableApproval from '@/components/proposal-tables/ProposalTableApproval.vue';
import ProposalTableReferendum from '@/components/proposal-tables/ProposalTableReferendum.vue';
import ProposalTableExecution from '@/components/proposal-tables/ProposalTableExecution.vue';
import { calculateTimeStr } from '@/utils/tools';

@Component({
  components: { ProposalTableExecution, ProposalTableReferendum, ProposalTableApproval, ProposalTableQueue, PageTabs },
  apollo: {
    getQueueCountDown: {
      query: require("../../apollo/graphql/QueueCountdown.gql"),
      result() {
        this.startCountdown();
      },
    }
  }
})
export default class ProposalListPage extends Vue {
  private tabIndex = 0;
  private tableTabIndex = 0;

  private getQueueCountDown = 0;
  private timer: any = null;

  get countdownTime() {
    return calculateTimeStr(this.getQueueCountDown);
  }

  private startCountdown() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      if (this.getQueueCountDown > 0) {
        this.getQueueCountDown -= 1;
        this.startCountdown();
      } else {
        this.$apollo.queries.getQueueCountDown.refetch();
      }
    }, 1000);
  }

  beforeDestroy() {
    clearTimeout(this.timer);
    this.timer = null;
  }
};
