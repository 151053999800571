
import { Component, Vue } from 'vue-property-decorator';
import AvatarPixel from '@/components/AvatarPixel.vue';
import { viewportToPixels } from '@/utils/tools';

@Component({
  components: { AvatarPixel },
  apollo: {
    pageProposal: {
      query: require("../../apollo/graphql/Proposals.gql"),
      variables() {
        return {
          stage: 2,
          page: this.currentPage,
        }
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    }
  }
})
export default class ProposalTableApproval extends Vue {
  private pageProposal: any = {};
  private currentPage = 1;
  private loading = false;
  get totalPage() {
    if (this.pageProposal.totalCount) {
      return Math.ceil(this.pageProposal.totalCount / 10);
    }
    return 0;
  }
  statusText(stage: number) {
    if (stage <= 4) {
      return "Ready";
    } else {
      return "";
    }
  }
  public convertViewPort(input: string): number {
    return viewportToPixels(input);
  }
}

