
import { UserModule } from '@/store/modules/user';
import { Component, Vue } from 'vue-property-decorator';
import AvatarPixel from '@/components/AvatarPixel.vue';
import { viewportToPixels } from '@/utils/tools';

@Component({
  components: { AvatarPixel },
  apollo: {
    pageProposal: {
      query: require("../../apollo/graphql/Proposals.gql"),
      variables() {
        return {
          stage: 1,
          page: this.currentPage,
        }
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    },
  }
})
export default class ProposalTableQueue extends Vue {

  private queue: any = [];

  private currentPage = 1;

  private pageProposal: any = {};

  private loading = false;

  get totalPage() {
    if (this.pageProposal.totalCount) {
      return Math.ceil(this.pageProposal.totalCount / 10);
    }
    return 0;
  }

  private async loadData() {
    if (UserModule.contractKit) {
      const res = await UserModule.contractKit.governanceContract.getQueue();
      this.queue = res;
    }
  }

  public convertViewPort(input: string): number {
    return viewportToPixels(input);
  }

  mounted() {
    this.loadData();
  }
}

